// src/components/SkillsList/SkillsList.js

import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { UserContext } from '../../contexts/UserContext';
import './SkillsList.css';

const SkillsList = () => {
  const { gradeLevel: paramGradeLevel, levelId: paramLevelId, focusArea: paramFocusArea } = useParams(); // Renamed to avoid confusion
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const [skills, setSkills] = useState([]);
  const [completedSkills, setCompletedSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Define focus area to levels mapping
  const focusAreaLevelsMapping = {
    Listening: ['level1'],
    Breathing: ['level2'],
    'Mindful Counting': ['level3'],
    // Add more mappings as needed
  };

  const fetchSkillsForFocusArea = useCallback(async () => {
    try {
      // Derive parameters from route or userData
      const gradeLevel = paramGradeLevel || userData.gradeLevel || 'grade1';
      const levelId = paramLevelId || (userData.currentLevel ? userData.currentLevel : 'level1'); // Ensure 'level1'
      const focusArea = paramFocusArea || 'Listening'; // Default or derive appropriately

      if (!focusArea) {
        setError('No focus area specified.');
        setLoading(false);
        return;
      }

      const levelsForFocus = focusAreaLevelsMapping[focusArea];
      if (!levelsForFocus || levelsForFocus.length === 0) {
        setSkills([]);
        setLoading(false);
        return;
      }

      const skillsPromises = levelsForFocus.map((levelIdForFocus) => {
        const skillsRef = collection(db, 'grades', gradeLevel, 'levels', levelIdForFocus, 'skills');
        return getDocs(skillsRef);
      });

      const skillsSnapshots = await Promise.all(skillsPromises);
      const allSkills = skillsSnapshots.flatMap((snapshot) =>
        snapshot.docs.map((doc) => ({
          skillId: doc.id,
          ...doc.data(),
        }))
      );

      // Debugging: Log fetched skills
      console.log('Fetched Skills in SkillsList:', allSkills);

      setSkills(allSkills);

      // Fetch user's completed skills for the current focus area
      if (userData && userData.completedSkills && userData.completedSkills[focusArea]) {
        setCompletedSkills(userData.completedSkills[focusArea]);
      } else {
        setCompletedSkills([]);
      }
    } catch (err) {
      console.error('Error fetching skills:', err);
      setError('Failed to load skills.');
    } finally {
      setLoading(false);
    }
  }, [paramGradeLevel, paramLevelId, paramFocusArea, focusAreaLevelsMapping, userData]);

  useEffect(() => {
    fetchSkillsForFocusArea();
  }, [fetchSkillsForFocusArea]);

  const handleSkillClick = (skill) => {
    // Derive parameters again to ensure consistency
    const gradeLevel = paramGradeLevel || userData.gradeLevel || 'grade1';
    const levelId = paramLevelId || (userData.currentLevel ? userData.currentLevel : 'level1');
    const focusArea = paramFocusArea || 'Listening'; // Ensure focusArea is correct

    navigate(`/grades/${encodeURIComponent(gradeLevel)}/levels/${encodeURIComponent(levelId)}/skills/${encodeURIComponent(focusArea)}/${encodeURIComponent(skill.skillId)}`);
  };

  if (loading) return <p>Loading skills...</p>;
  if (error) return <p>{error}</p>;

  if (skills.length === 0) {
    return (
      <div className="no-skills-container">
        <h2>Skills for {paramFocusArea || 'Listening'}</h2>
        <p>No skills found for this focus area.</p>
        <button onClick={() => navigate(-1)} className="back-button">
          Go Back
        </button>
      </div>
    );
  }

  return (
    <div className="skills-list-container">
      <h2>Skills for {paramFocusArea || 'Listening'}</h2>
      <ul className="skills-list">
        {skills.map((skill) => (
          <li
            key={skill.skillId}
            className={`skill-item ${
              completedSkills.includes(skill.skillId) ? 'completed' : 'incomplete'
            }`}
          >
            <div className="skill-info">
              <span className="skill-name">{skill.name}</span>
              <span className="skill-status">
                {completedSkills.includes(skill.skillId) ? '✅ Completed' : '❌ Incomplete'}
              </span>
            </div>
            <button onClick={() => handleSkillClick(skill)} className="view-details-button">
              View Details
            </button>
          </li>
        ))}
      </ul>
      <button onClick={() => navigate(-1)} className="back-button">
        Go Back
      </button>
    </div>
  );
};

export default SkillsList;
