// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client'; // Ensure you are using React 18+
import App from './App';
import { UserProvider } from './contexts/UserContext';
import './styles/styles.css'; // Ensure your global styles are imported

const container = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(container);

// Initial render
root.render(
  <React.StrictMode>
    <UserProvider>
      <App />
    </UserProvider>
  </React.StrictMode>
);
