// src/firebase/firebase.js

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; // Added import
import { getAuth } from 'firebase/auth'; // Added import
// import { getAnalytics } from 'firebase/analytics'; // Remove if not used

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAqW78DF5KodFKMqS3Qs9Rjw30FtU03Uu8",
  authDomain: "mindfulness-website-ec775.firebaseapp.com",
  projectId: "mindfulness-website-ec775",
  storageBucket: "mindfulness-website-ec775.firebasestorage.app",
  messagingSenderId: "561664961457",
  appId: "1:561664961457:web:571f870c07fbbc7bf16b90",
  measurementId: "G-XTWZ2YNCC4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Firestore Database
const db = getFirestore(app);

// Firebase Authentication
const auth = getAuth(app);

export { db, auth };
