// src/App.js

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/styles.css';
import { UserProvider, UserContext } from './contexts/UserContext';
import ErrorBoundary from './components/Home/sections/ErrorBoundary';
import SkillsList from './components/SkillsList/SkillsList';

// Lazy load components for performance optimization
const Navbar = lazy(() => import('./components/Navbar/Navbar'));
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const Home = lazy(() => import('./components/Home/Home'));
const Login = lazy(() => import('./components/Login/Login'));
const SignUp = lazy(() => import('./components/SignUp/SignUp'));
const ResetPassword = lazy(() => import('./components/ResetPassword/ResetPassword')); // New ResetPassword component
const MeditationCard = lazy(() => import('./components/MeditationCard/MeditationCard'));
const LevelsOverview = lazy(() => import('./components/LevelsOverview/LevelsOverview'));
const LevelDetail = lazy(() => import('./components/LevelDetail/LevelDetail'));
const ChallengeDetail = lazy(() => import('./components/ChallengeDetail/ChallengeDetail'));
const BadgesPage = lazy(() => import('./components/BadgesPage/BadgesPage')); // Updated BadgesPage
const PreferencesPage = lazy(() => import('./components/PreferencesPage/PreferencesPage'));
const UserHome = lazy(() => import('./components/UserHome/UserHome'));
const FreePreview = lazy(() => import('./components/FreePreview/FreePreview'));
const AddInitialData = lazy(() => import('./components/AddInitialData/AddInitialData'));
const GrantAdmin = lazy(() => import('./components/GrantAdmin/GrantAdmin'));
const NotFound = lazy(() => import('./components/NotFound/NotFound')); // 404 Page
const AdminRoleAssigner = lazy(() => import('./components/AdminRoleAssigner/AdminRoleAssigner'));
const FocusAreaDetail = lazy(() => import('./components/FocusAreaDetail/FocusAreaDetail'));
const SkillDetail = lazy(() => import('./components/SkillDetail/SkillDetail')); // Import SkillDetail

// ProtectedRoute component that consumes UserContext
function ProtectedRoute({ children }) {
  const { userData, loading } = React.useContext(UserContext);

  if (loading) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  }

  return userData ? children : <Navigate to="/login" />;
}

// AdminRoute component to protect admin-only routes
function AdminRoute({ children }) {
  const { userData, loading } = React.useContext(UserContext);

  if (loading) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  }

  // Check if the user is admin
  return userData && userData.isAdmin ? children : <Navigate to="/dashboard" />;
}

function App() {
  return (
    <Router>
      <UserProvider>
        <ErrorBoundary>
          {/* Navbar */}
          <Suspense
            fallback={
              <div className="loading-container">
                <p>Loading Navbar...</p>
              </div>
            }
          >
            <Navbar />
          </Suspense>

          {/* Toast Notifications */}
          <ToastContainer />

          {/* Main Content */}
          <Suspense
            fallback={
              <div className="loading-container">
                <p>Loading Page...</p>
              </div>
            }
          >
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/reset-password" element={<ResetPassword />} /> {/* New route */}
              <Route path="/free-preview" element={<FreePreview />} />

              {/* Preferences Page - Accessible after sign-up */}
              <Route
                path="/preferences"
                element={
                  <ProtectedRoute>
                    <PreferencesPage />
                  </ProtectedRoute>
                }
              />

              {/* Protected Routes */}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/meditation"
                element={
                  <ProtectedRoute>
                    <MeditationCard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/levels"
                element={
                  <ProtectedRoute>
                    <LevelsOverview />
                  </ProtectedRoute>
                }
              />

              {/* Nested Routes for Grades and Levels */}
              <Route
                path="/grades/:gradeLevel/levels/:levelId"
                element={
                  <ProtectedRoute>
                    <LevelDetail />
                  </ProtectedRoute>
                }
              />

              {/* Skills List for Specific Focus Area */}
              <Route
                path="/grades/:gradeLevel/levels/:levelId/skills/:focusArea"
                element={
                  <ProtectedRoute>
                    <SkillsList />
                  </ProtectedRoute>
                }
              />

              {/* Challenge Detail */}
              <Route
                path="/challenges/:gradeLevel/levels/:levelId/:focusArea"
                element={
                  <ProtectedRoute>
                    <ChallengeDetail />
                  </ProtectedRoute>
                }
              />

              {/* Badges */}
              <Route
                path="/badges"
                element={
                  <ProtectedRoute>
                    <BadgesPage />
                  </ProtectedRoute>
                }
              />

              {/* User Home */}
              <Route
                path="/user-home"
                element={
                  <ProtectedRoute>
                    <UserHome />
                  </ProtectedRoute>
                }
              />

              {/* Admin-Only Routes */}
              <Route
                path="/admin-dashboard"
                element={
                  <AdminRoute>
                    <AdminRoleAssigner />
                  </AdminRoute>
                }
              />
              <Route
                path="/add-initial-data"
                element={
                  <AdminRoute>
                    <AddInitialData />
                  </AdminRoute>
                }
              />
              <Route
                path="/grant-admin"
                element={
                  <AdminRoute>
                    <GrantAdmin />
                  </AdminRoute>
                }
              />

              {/* Focus Area Detail */}
              <Route 
                path="/focus-areas/:focusArea"
                element={
                  <ProtectedRoute>
                    <FocusAreaDetail />
                  </ProtectedRoute>
                } 
              />

              {/* Skill Detail Route - Nested */}
              <Route
                path="/grades/:gradeLevel/levels/:levelId/skills/:focusArea/:skillId"
                element={
                  <ProtectedRoute>
                    <SkillDetail />
                  </ProtectedRoute>
                }
              />

              {/* Skill Detail Route - Flat Path */}
              <Route
                path="/skills/:skillId"
                element={
                  <ProtectedRoute>
                    <SkillDetail />
                  </ProtectedRoute>
                }
              />

              {/* 404 Not Found Route */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </UserProvider>
    </Router>
  );
}

export default App;
