// src/contexts/UserContext.js

import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, onSnapshot } from 'firebase/firestore';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null); 
  const [loading, setLoading] = useState(true);   
  const [error, setError] = useState(null);       

  useEffect(() => {
    let unsubscribeUser = () => {};

    // Listen for authentication state changes
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Retrieve the ID token to check for admin claims
          const tokenResult = await user.getIdTokenResult();
          const isAdmin = tokenResult.claims.admin || false;

          // Reference to the user's document in Firestore
          const userRef = doc(db, 'users', user.uid);

          // Listen for real-time updates to the user document
          unsubscribeUser = onSnapshot(
            userRef,
            async (docSnap) => {
              if (docSnap.exists()) {
                const data = docSnap.data();

                // Ensure currentLevel is a string
                let currentLevelId = data.currentLevel || 'level1';
                if (typeof currentLevelId === 'number') {
                  currentLevelId = `level${currentLevelId}`;
                }

                setUserData({
                  uid: user.uid,
                  email: user.email,
                  isAdmin: isAdmin,
                  ...data,
                  currentLevel: currentLevelId, // Ensure it's a string
                });
              } else {
                // No user data in Firestore; create initial document with necessary fields
                await setDoc(userRef, {
                  email: user.email,
                  gradeLevel: '',
                  className: '',
                  avatar: '',
                  currentLevel: 'level1', // Set as string
                  progress: 0,
                  earnedBadges: [],
                  completedMeditations: [],
                  completedCards: [],
                  completedChallenges: [],
                  // Instead of a single completedSkills object, we use completedSkillsByGrade
                  completedSkillsByGrade: {},
                  progressHistory: [],
                  skillCompletionHistory: [],
                  streak: 0,
                  streakHistory: [],
                  lastMeditationDate: null,
                  theme: 'light',
                  achievements: [],
                  badges: [],
                  hasCompletedOnboarding: false,
                  completedLevels: [],
                });

                setUserData({
                  uid: user.uid,
                  email: user.email,
                  isAdmin: isAdmin,
                  gradeLevel: '',
                  className: '',
                  avatar: '',
                  currentLevel: 'level1', // Set as string
                  progress: 0,
                  earnedBadges: [],
                  completedMeditations: [],
                  completedCards: [],
                  completedChallenges: [],
                  completedSkillsByGrade: {},
                  progressHistory: [],
                  skillCompletionHistory: [],
                  streak: 0,
                  streakHistory: [],
                  lastMeditationDate: null,
                  theme: 'light',
                  achievements: [],
                  badges: [],
                  hasCompletedOnboarding: false,
                  completedLevels: [],
                });
              }
              setLoading(false);
            },
            (error) => {
              console.error('Error fetching user document:', error);
              setError('Failed to load user data. Please try again later.');
              setLoading(false);
            }
          );
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError('Failed to load user data. Please try again later.');
          setUserData(null);
          setLoading(false);
        }
      } else {
        // User is signed out; reset userData
        setUserData(null);
        setLoading(false);
      }
    });

    // Cleanup subscriptions on unmount
    return () => {
      unsubscribeAuth();
      unsubscribeUser();
    };
  }, []);

  return (
    <UserContext.Provider value={{ userData, setUserData, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};
